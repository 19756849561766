import {
  formatToIDRCurrency,
  isEmptyObject,
} from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { TranslateFunction } from '@topremit/shared-web/hooks/use-translation';
import Router from 'next/router';

export function removeQuoteQueryParams(data: string[] = []) {
  if (!isNativeApp() && Router.query) {
    //default params
    const {
      amount,
      country,
      currency,
      service,
      routingChannel,
      recipient,
      ...restQuery
    } = Router.query;

    const filteredQuery = !isEmptyObject(restQuery)
      ? Object.fromEntries(
          Object.entries(restQuery).filter(([key]) => !data.includes(key)),
        )
      : restQuery;

    Router.replace({ pathname: '/quote', query: filteredQuery }, undefined, {
      shallow: true,
    });
  }
}

export function getServiceDiscountBadge(
  isAllChannelFree: boolean,
  isDiscountFeeEqual: boolean,
  highestDiscount: number,
  t: TranslateFunction<'home'>,
) {
  if (isAllChannelFree) {
    return t('free').toUpperCase();
  }

  if (isDiscountFeeEqual) {
    return t('discount_off', {
      discount: formatToIDRCurrency(highestDiscount),
    });
  }

  return t('discount_up_to', {
    discount: formatToIDRCurrency(highestDiscount),
  });
}

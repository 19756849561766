import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface AirtimeForm {
  /** @example: +6282277544856 */
  msisdn: string;
  /** @example: 82277544856 */
  inputmsisdn: string;
  /** @example: 62 */
  selectmsisdn: string;

  price: string;
  product: string;
  totalPrice: string;
}

export const airtimeInitialValues = {
  inputmsisdn: '',
  msisdn: '',
  price: '',
  product: '',
  selectmsisdn: '',
  totalPrice: '',
};

export interface AirtimeStore {
  values: AirtimeForm;
  setValues: (param: AirtimeForm) => void;
}

export const useAirtimeStore = create<AirtimeStore>()(
  persist(
    (set) => ({
      values: airtimeInitialValues,
      setValues: (param: AirtimeForm) =>
        set((state) => ({
          values: { ...state.values, ...param },
        })),
    }),
    {
      name: 'airtime-store',
      partialize: (state) => ({ values: state.values }),
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

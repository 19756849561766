import { useGetCountries } from '@api-hooks/common';
import { useGetAirtimeProduct, useSetAirtime } from '@api-hooks/transaction';
import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAnalytics } from '@hooks';
import { useDebounceCallback } from '@hooks/use-debounce-callback';
import {
  TransactionRecipientType,
  TransactionType,
} from '@topremit/shared-web/api-hooks/transaction';
import {
  areObjEqual,
  isEmptyObject,
  isStringEmpty,
} from '@topremit/shared-web/common/helper';
import {
  Button,
  Flex,
  PhoneNumberInputV2,
  Text,
} from '@topremit/shared-web/components/elements';
import {
  Select,
  SelectItem,
  SelectList,
} from '@topremit/shared-web/components/elements/select';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ICalculatorAirtime } from '../Calculator.typing';
import { removeQuoteQueryParams } from '../helper';
import {
  AirtimeForm,
  airtimeInitialValues,
  useAirtimeStore,
} from '../use-airtime-store';
import CalculatorAirtimeEmptyState from './CalculatorAirtimeEmptyState';
import CalculatorAirtimeQuotationInfo from './CalculatorAirtimeQuotationInfo';

interface CurrentProduct {
  country: string;
  itemId: string;
}

export default function CalculatorAirtime({ targetRef }: ICalculatorAirtime) {
  const { t } = useTranslation();
  const {
    analyticsPersonal: { tracksPersonal },
  } = useAnalytics();
  const { addNotification } = useNotification();

  const currentProduct = useRef<CurrentProduct>();

  const router = useRouter();
  const isDashboardPage = router.pathname.includes('quote');
  const defaultdialCodeCountry = String(router.query.country ?? 'SGP');

  const { values: airtimeStore, setValues: setAirtimeStore } =
    useAirtimeStore();

  const methods = useForm<AirtimeForm>({
    resolver: yupResolver(
      yup.object().shape({
        msisdn: yup.string().required(t('error:field.required')),
        product: yup.string().required(t('error:field.required_choose')),
      }),
    ) as any,
  });

  const {
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = watch();

  const { data: countries, isLoading: isLoadingCountries } = useGetCountries(
    {
      available: true,
      type: TransactionType.AIRTIME,
    },
    {
      enabled: true,
      onSuccess: () => {
        if (airtimeStore) {
          reset({
            inputmsisdn: airtimeStore.inputmsisdn,
            msisdn: airtimeStore.msisdn,
            price: airtimeStore.price,
            selectmsisdn: airtimeStore.selectmsisdn,
            totalPrice: airtimeStore.price,
          });
        }
      },
    },
  );

  const {
    data: airtime,
    refetch: refetchAirtime,
    isLoading: isLoadingAirtime,
  } = useGetAirtimeProduct(values.msisdn, {
    onSuccess: ({ data: { country, destinationCurrency, products } }) => {
      clearErrors();
      if (isDashboardPage && !areObjEqual(airtimeStore, airtimeInitialValues)) {
        /** Use to continue create airtime quotation from landing page */
        setValue('product', airtimeStore.product);
        setAirtimeStore(airtimeInitialValues);
      } else {
        resetProductAndPrice();
      }

      const productName = router.query.product;
      if (productName) {
        const selectedProduct = products.find(
          (product) => product.id === productName,
        );
        const selectedProductName = selectedProduct?.name;
        const selectedProductPrice = selectedProduct?.price;
        const totalPrice = selectedProduct?.totalPrice;
        if (selectedProductPrice && selectedProductName && totalPrice) {
          setValue('product', String(selectedProductName));
          setValue('price', String(selectedProductPrice));
          setValue('totalPrice', totalPrice);
        }
        return removeQuoteQueryParams(['msisdn', 'product']);
      }

      if (country && destinationCurrency && products) {
        const itemId = `Mobile Top up - ${country} - ${destinationCurrency} - ${
          products[0].name.split(' ')[1]
        }`;
        tracksPersonal.viewItem({
          item_id: itemId,
          item_name: itemId,
          item_type: TransactionType.AIRTIME,
        });
        currentProduct.current = {
          itemId,
          country,
        };
      }
    },
    onError: ({ message, errors }) => {
      resetProductAndPrice();
      addNotification({ message, type: 'danger' });
      if (errors) {
        Object.keys(errors).forEach((key) => {
          setError(key as any, {
            message: errors[key],
          });
        });
      }
    },
  });

  const { mutate: mutateAirtime, isLoading: isLoadingMutateAirtime } =
    useSetAirtime({
      onSuccess: ({ data: { refId } }) => {
        if (refId) {
          // Note: this expected only personal
          tracksPersonal.beginCheckout({
            transaction_id: String(refId),
            type: TransactionType.AIRTIME,
          });
          router.replace(
            `/activity/transaction/create/${refId}/AIRTIME/confirm-transaction-details`,
          );
        }
      },
      onError: ({ statusCode, message }) => {
        // Note: this expected hit analytic Personal only
        tracksPersonal.failedAddToCart({
          item_type: TransactionType.AIRTIME,
          error: message,
          type: TransactionRecipientType.SINGLE,
        });

        if (statusCode === 401) {
          setAirtimeStore(values);
          router.push({
            pathname: '/quote',
            query: {
              tab: 'AIRTIME',
            },
          });
          return;
        }

        addNotification({ message, type: 'danger' });
      },
    });

  const { products, note, operator, fee, msisdn } = airtime?.data || {};

  const productOptions = useMemo(() => {
    return products?.map(({ name, price }) => ({
      label: name,
      value: price,
    }));
  }, [products]);

  const isLoadingData = isLoadingCountries || isLoadingAirtime;
  const isEmptyPhone = !values.msisdn || isLoadingAirtime || !productOptions;
  const isDisabledSelectProduct = isLoadingData || isEmptyPhone;
  const canShowQuotation =
    !isStringEmpty(values.msisdn) &&
    !isStringEmpty(values.product) &&
    !isLoadingCountries &&
    !isLoadingAirtime &&
    airtime;

  function onSubmit({ product }: AirtimeForm) {
    if (product && msisdn) {
      mutateAirtime({
        msisdn: `+${msisdn}`,
        product: String(product),
      });
    }
  }

  function resetProductAndPrice() {
    setValue('price', '');
    setValue('product', '');
  }

  function handleChangeCountry() {
    resetProductAndPrice();
  }

  function handleChangeProduct(productName: string) {
    const product = products?.find((product) => product.name === productName);

    if (product) {
      setValue('price', product.price);
      setValue('totalPrice', product.totalPrice);

      if (currentProduct.current) {
        const itemId = `Mobile Top up - ${values.selectmsisdn} - ${
          currentProduct.current.country
        } - ${product.name.split(' ')[1]}`;

        tracksPersonal.viewItem({
          // item_id and item_name are meant to be same as requested from data squad
          item_id: itemId,
          item_name: itemId,
          item_type: TransactionType.AIRTIME,
          prev_item_name: currentProduct.current?.itemId,
        });
        currentProduct.current.itemId = itemId;
      }
    }
  }

  const handleChangePhone = useDebounceCallback((selectmsisdn: string) => {
    if (selectmsisdn) {
      refetchAirtime();
    } else {
      resetProductAndPrice();
    }
  }, 1500);

  /**
   * Set msisdn for recreating airtime using useEffect,
   * as countries are only fetched once during the initial load.
   *  */
  useEffect(() => {
    if (countries && router.query.msisdn && router.query.country) {
      const decodeMsisdn = decodeURIComponent(String(router.query.msisdn));
      setValue('msisdn', decodeMsisdn);
    }
  }, [router.isReady, countries]);

  useEffect(() => {
    if (!isEmptyObject(errors?.msisdn)) {
      tracksPersonal.addQuotationDetail({
        section: 'phonenumber',
        error: 'phonenumber',
        item_type: TransactionType.AIRTIME,
      });
    }
  }, [errors?.msisdn]);

  return (
    <FormProvider {...methods}>
      <Flex column align="center" className={styled.root}>
        <PhoneNumberInputV2
          name="msisdn"
          label={t('home:jumbotron.calculator.airtime.phone')}
          disabled={isLoadingData}
          dialCodeOptions={countries?.data}
          defaultValue={{
            dialCodeCountry: defaultdialCodeCountry,
          }}
          onChangeCountry={handleChangeCountry}
          onChange={handleChangePhone}
          control={methods.control}
        />
        {isEmptyPhone ? (
          <CalculatorAirtimeEmptyState />
        ) : (
          <Select
            control={methods.control}
            name="product"
            fullWidth
            label={t('home:jumbotron.calculator.airtime.products')}
            disabled={isDisabledSelectProduct}
            renderValue={(value) =>
              productOptions.find((product) => product.label === value)?.label
            }
            onChange={handleChangeProduct}
          >
            <SelectList>
              {productOptions?.map(({ label, value }) => (
                <SelectItem key={label} value={label}>
                  <Text minSize="sm" maxSize="p" className={styled.productName}>
                    {label}
                  </Text>
                  <Text
                    minSize="xs"
                    maxSize="sm"
                    className={styled.productPrice}
                  >
                    {t('price')}: <b>{value}</b>
                  </Text>
                </SelectItem>
              ))}
            </SelectList>
          </Select>
        )}

        {canShowQuotation && (
          <CalculatorAirtimeQuotationInfo
            fee={fee}
            note={note}
            operator={operator}
            price={values.price}
            totalPrice={values.totalPrice}
          />
        )}

        {targetRef?.current &&
          createPortal(
            <Button
              fullWidth
              disabled={isLoadingData}
              isLoading={isLoadingMutateAirtime}
              onClick={handleSubmit(onSubmit)}
            >
              {t('top_up_now')}
            </Button>,
            targetRef.current,
          )}
      </Flex>
    </FormProvider>
  );
}

const styled = {
  root: css`
    flex: 1;
    display: flex;
    gap: 1rem;
    > .form-group {
      margin: 0;
    }
  `,
  productName: css`
    margin-bottom: 0.25rem;
    font-weight: var(--bold-font-weight);
  `,
  productPrice: css`
    color: var(--text-secondary);
  `,
};

import { css, cx } from '@emotion/css';
import { Flex, Text } from '@topremit/shared-web';

interface IQuotationItem {
  label: string;
  value: string;
  className?: string;
}

export const CalculatorAirtimeQuotationItem = ({
  label,
  value,
  className,
}: IQuotationItem) => (
  <Flex className={cx(styled.quotationItem, className)}>
    <Text minSize="sm" maxSize="p">
      {label}
    </Text>
    <Text minSize="sm" maxSize="p">
      {value}
    </Text>
  </Flex>
);

const styled = {
  quotationItem: css`
    justify-content: space-between;
    margin-bottom: 0.5rem;
  `,
};

import { css, cx } from '@emotion/css';
import {
  Divider,
  Flex,
  parseHtml,
  Text,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';

import { CalculatorAirtimeQuotationItem } from './CalculatorAirtimeQuotationItem';

type Props = Partial<{
  fee: string;
  note: string;
  operator: string;
  price: string;
  totalPrice: string;
}>;

export default function CalculatorAirtimeQuotationInfo({
  fee,
  note,
  operator,
  price,
  totalPrice,
}: Props) {
  const { t } = useTranslation();

  return (
    <Flex column className={cx('card card-secondary', styled.cardWrapper)}>
      {operator && (
        <CalculatorAirtimeQuotationItem
          label={t('home:jumbotron.calculator.airtime.operator')}
          value={operator}
        />
      )}
      {price && (
        <CalculatorAirtimeQuotationItem
          label={t('home:jumbotron.calculator.airtime.price')}
          value={price}
        />
      )}
      {fee && (
        <CalculatorAirtimeQuotationItem
          label={t('home:jumbotron.calculator.airtime.fee')}
          value={fee}
        />
      )}

      <Divider variant="dashed" my={16} />

      {totalPrice && (
        <CalculatorAirtimeQuotationItem
          label={t('total_amount')}
          value={totalPrice}
          className={styled.totalPrice}
        />
      )}

      {note && <Text className={styled.note}>{parseHtml(note)}</Text>}
    </Flex>
  );
}

const styled = {
  cardWrapper: css`
    padding: 1rem 1.25rem;
    margin-bottom: 1.5rem;
    border: 1px solid var(--neutral-200);
    overflow: hidden;
  `,

  note: css`
    text-align: center;
    font-size: 0.75rem;
    background: var(--neutral-100);
    color: var(--text-secondary);
    margin: 0 -1.25rem -1.125rem;
    padding: 0.5rem 1rem;
  `,
  totalPrice: css`
    font-weight: var(--bold-font-weight);
    margin-bottom: 1rem;
  `,
};

import { css } from '@emotion/css';
import imgBolyThumbsUp from '@public/images/svg/boly-thumbs-up-with-app.svg';
import { fontSize } from '@topremit/shared-web/common/size';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';

export default function CalculatorAirtimeEmptyState() {
  const { t } = useTranslation('home');

  return (
    <Flex column className={styled.illustration}>
      <Flex className={styled.imgWrapper}>
        <Image fill src={imgBolyThumbsUp} alt="airtime" />
      </Flex>
      <Text className={styled.illusDesc}>
        {t('jumbotron.calculator.airtime.fill_phone_number')}
      </Text>
    </Flex>
  );
}

const styled = {
  illustration: css`
    flex: 1;
    align-items: center;
    justify-content: center;
  `,
  imgWrapper: css`
    position: relative;
    aspect-ratio: 1;
    max-width: 9.375rem;
    margin-bottom: 0.5rem;
  `,
  illusDesc: css`
    font-size: ${fontSize.sm};
    text-align: center;
  `,
};
